import React, { Component } from "react"
import SocialLinks from "./socialLinks"
import { Navbar, Nav, NavDropdown, Dropdown, NavItem } from "react-bootstrap"
import {
  FaLock,
  FaPen,
  FaUser,
  FaUserSecret,
  FaSignOutAlt,
  FaDoorOpen,
} from "react-icons/fa"
import { getCurrentUser, logout } from "../services/authService"
import { anonymousUser } from "../config.json"
import NavLink from "react-bootstrap/NavLink"

const fixedCategories = [
  {
    type: "categories",
    id: 35,
    locale: "it",
    attributes: {
      type: "articles",
      slug: "soluzioni-per-lenti-a-contatto",
      name: "Soluzioni per lenti a contatto",
      description: "Detergenti per tutti i tipi di lenti a contatto",
      created_at: "2019-04-10T15:45:26.000000Z",
      updated_at: "2019-04-10T15:45:26.000000Z",
    },
    relationships: {
      parent: {
        data: {
          type: "categories",
          id: 28,
          attributes: {
            type: "articles",
            slug: "prodotti",
            name: "Prodotti",
            description: "Sceglia tra la vasta gamma dei nostri prodotti",
            created_at: "2019-04-10T15:24:00.000000Z",
            updated_at: "2019-04-10T15:24:00.000000Z",
          },
        },
      },
    },
  },
  {
    type: "categories",
    id: 36,
    locale: "it",
    attributes: {
      type: "articles",
      slug: "lenti-a-contatto",
      name: "Lenti a contatto",
      description: "Tutta la nostra gamma",
      created_at: "2019-04-10T15:45:58.000000Z",
      updated_at: "2019-04-10T15:45:58.000000Z",
    },
    relationships: {
      parent: {
        data: {
          type: "categories",
          id: 28,
          attributes: {
            type: "articles",
            slug: "prodotti",
            name: "Prodotti",
            description: "Sceglia tra la vasta gamma dei nostri prodotti",
            created_at: "2019-04-10T15:24:00.000000Z",
            updated_at: "2019-04-10T15:24:00.000000Z",
          },
        },
      },
    },
  },
  {
    type: "categories",
    id: 37,
    locale: "it",
    attributes: {
      type: "articles",
      slug: "lenti-a-contatto-di-costruzione",
      name: "Lenti a contatto di costruzione",
      description: "Lenti a contatto di costruzione",
      created_at: "2019-04-15T08:39:40.000000Z",
      updated_at: "2019-04-15T08:39:40.000000Z",
    },
    relationships: {
      parent: {
        data: {
          type: "categories",
          id: 28,
          attributes: {
            type: "articles",
            slug: "prodotti",
            name: "Prodotti",
            description: "Sceglia tra la vasta gamma dei nostri prodotti",
            created_at: "2019-04-10T15:24:00.000000Z",
            updated_at: "2019-04-10T15:24:00.000000Z",
          },
        },
      },
    },
  },
  {
    type: "categories",
    id: 38,
    locale: "it",
    attributes: {
      type: "articles",
      slug: "per-occhiali-e-altri-dispositivi",
      name: "Per occhiali ed altri dispositivi",
      description: "Per occhiali ed altri dispositivi",
      created_at: "2019-04-15T08:39:55.000000Z",
      updated_at: "2019-04-15T08:39:55.000000Z",
    },
    relationships: {
      parent: {
        data: {
          type: "categories",
          id: 28,
          attributes: {
            type: "articles",
            slug: "prodotti",
            name: "Prodotti",
            description: "Sceglia tra la vasta gamma dei nostri prodotti",
            created_at: "2019-04-10T15:24:00.000000Z",
            updated_at: "2019-04-10T15:24:00.000000Z",
          },
        },
      },
    },
  },
]

class Menu extends Component {
  state = {
    user: { ...anonymousUser },
    userIsClient: false,
    categories: [],
  }

  componentDidMount = async () => {
    let user = await getCurrentUser()
    let userIsClient = true
    try {
      userIsClient = user.relationships.roles.data[0].id === 3
    } catch (error) {}

    this.setState({ user, userIsClient })

    //const data = await getItems("categories/prodotti/children")
    //let categories = data.data
    let categories = [...fixedCategories]
    this.setState({ categories })
  }

  handleLogout = async e => {
    e.preventDefault()
    logout()
    const user = { ...anonymousUser }
    const userIsClient = true
    this.setState({ user, userIsClient })
    window.location = "/login"
  }

  render() {
    const { user, userIsClient, categories } = this.state

    return (
      <div className={"menu-wrapper"}>
        <div className="container">
          <div className="row">
            <div className="col d-flex align-items-center justify-content-end">
              <SocialLinks />

              <div className="dropdown customersarea-navigation customersarea-navigation-desktop">
                <button
                  className="dropdown-toggle customersarea-navigation-button"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {user.id === 0 && <FaLock className="mr-2" />}
                  {user.id > 0 &&
                    user.relationships !== undefined &&
                    userIsClient && <FaUser className="mr-2" />}
                  {user.id > 0 &&
                    user.relationships !== undefined &&
                    !userIsClient && <FaUserSecret className="mr-2" />}
                  Area Clienti
                </button>

                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Nav>
                    {user.id === 0 && (
                      <li className="dropdown-item">
                        <Nav.Link href="/login">
                          <FaLock /> Accedi
                        </Nav.Link>
                      </li>
                    )}

                    {!(user.id === 0) && (
                      <li className="dropdown-item">
                        <Nav.Link href="/area-clienti">
                          <FaDoorOpen /> Entra
                        </Nav.Link>
                      </li>
                    )}

                    <li className="dropdown-divider" />

                    {user.id === 0 && (
                      <li className="dropdown-item with-icon">
                        <Nav.Link href="/registrazione">
                          <FaPen /> Registrati
                        </Nav.Link>
                      </li>
                    )}

                    {!(user.id === 0) && (
                      <li className="dropdown-item with-icon">
                        <Nav.Link href="/" onClick={this.handleLogout}>
                          <FaSignOutAlt /> Logout
                        </Nav.Link>
                      </li>
                    )}
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col">
                <Navbar collapseOnSelect expand="lg">
                  <Navbar.Brand href="/">
                    <img
                      src={require("../images/logo_orange.png")}
                      alt="VisionCare"
                      className={"main-logo"}
                    />
                  </Navbar.Brand>

                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link href="/news">News</Nav.Link>

                      <NavDropdown title="Prodotti" id="products-nav-dropdown">
                        {categories &&
                          categories.map(category => (
                            <NavDropdown.Item
                              href={
                                "/prodotti/" + category.attributes.slug + "/"
                              }
                              key={category.id}
                            >
                              {category.attributes.name}
                            </NavDropdown.Item>
                          ))}
                      </NavDropdown>

                      <Nav.Link href="/azienda">Azienda</Nav.Link>

                      <Nav.Link href="/contatti">Contattaci</Nav.Link>

                      <NavDropdown.Divider />

                      <Dropdown
                        as={NavItem}
                        className={"customersarea-navigation-mobile"}
                      >
                        <Dropdown.Toggle
                          id="customersarea-nav-dropdown"
                          className={"customersarea-navigation-button"}
                          as={NavLink}
                        >
                          {user.id === 0 && <FaLock className="mr-2" />}
                          {user.id > 0 &&
                            user.relationships !== undefined &&
                            userIsClient && <FaUser className="mr-2" />}
                          {user.id > 0 &&
                            user.relationships !== undefined &&
                            !userIsClient && <FaUserSecret className="mr-2" />}
                          Area Clienti
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {user.id === 0 && (
                            <Dropdown.Item href={"/login/"}>
                              <FaLock /> Accedi
                            </Dropdown.Item>
                          )}

                          {!(user.id === 0) && (
                            <Dropdown.Item href={"/area-clienti"}>
                              <FaDoorOpen /> Entra
                            </Dropdown.Item>
                          )}

                          <Dropdown.Divider />

                          {user.id === 0 && (
                            <Dropdown.Item href={"/registrazione"}>
                              <FaPen /> Registrati
                            </Dropdown.Item>
                          )}

                          {!(user.id === 0) && (
                            <Dropdown.Item onSelect={this.handleLogout}>
                              <FaSignOutAlt /> Logout
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Menu
