import React, { Component } from "react"

class SocialLinks extends Component {
  render() {
    return (
      <ul className="social-links d-flex list-inline justify-content-center">
        <li className="facebook list-inline-item">
          <a
            href="https://www.facebook.com/visioncareitalia/"
            target="_blank"
            rel="noopener noreferrer"
            title="VisionCare Italia su Facebook"
          >
            <span className="sr-only">Visita la pagina Facebook</span>
          </a>
        </li>

        <li className="instagram list-inline-item">
          <a
            href="https://www.facebook.com/visioncareitalia/"
            target="_blank"
            rel="noopener noreferrer"
            title="VisionCare Italia su Instagram"
          >
            <span className="sr-only">Visita la pagina Instagram</span>
          </a>
        </li>

        <li className="youtube list-inline-item">
          <a
            href="https://www.facebook.com/visioncareitalia/"
            target="_blank"
            rel="noopener noreferrer"
            title="VisionCare Italia su Youtube"
          >
            <span className="sr-only">Visita la pagina Youtube</span>
          </a>
        </li>
      </ul>
    )
  }
}

export default SocialLinks
