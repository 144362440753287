/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import SocialLinks from "./socialLinks"
import Menu from "./menu"
import "react-toastify/dist/ReactToastify.css"
import "../styles/app.scss"
import { ToastContainer } from "react-toastify"

const Layout = ({ children, classes }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={"main-wrapper " + classes}>
        <ToastContainer position="top-center" />

        <header id="main-header">
          <Menu />
        </header>

        <main>{children}</main>

        <footer id="main-footer">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="logo-wrapper">
                  <img
                    src={require("../images/logo_white.png")}
                    alt="Vision Care"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <h4>
                  <strong>Prodotti</strong>
                </h4>
                <ul>
                  <li>Soluzioni per lenti a contatto</li>
                  <li>Lenti a contatto</li>
                  <li>Lenti a contatto di costruzione</li>
                  <li>Per occhiali e altri dispositivi</li>
                </ul>
              </div>

              <div className="col-md-6 col-lg-4">
                <h4>
                  <strong>Azienda</strong>
                </h4>

                <ul>
                  <li>La nostra mission</li>
                  <li>Privacy Policy</li>
                  <li>Cookies Policy</li>
                </ul>
              </div>

              <div className="col-md-6 col-lg-4">
                <h4>
                  <strong>Contatti</strong>
                </h4>

                <ul>
                  <li>Tel. +39 049 704599</li>
                  <li>Fax +39 049 8876292</li>
                  <li>servizioclienti@visioncareitalia.it</li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <SocialLinks />

                <p className="company-info">
                  <span className="adr">
                    <span className="fn org">Vision Care Italia Srl</span> -{" "}
                    <span className="street-address">
                      Viale del Lavoro, 10
                    </span>{" "}
                    - <span className="postal-code">45100</span>{" "}
                    <span className="locality">Rovigo</span>(
                    <span className="region">
                      <abbr title="Padova">RO</abbr>
                    </span>
                    )<br />
                  </span>
                  Capitale sociale € 111.000 I.V.- REA RO-444030 -
                  <abbr title="Codice Fiscale">C.F.</abbr>/
                  <abbr title="Partita IVA">P.IVA</abbr> 04065270284
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
}

export default Layout
