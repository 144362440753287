import http from "./httpService"
import { anonymousUser } from "../config.json"
import { manageErrors, isBrowser, initLocalCart } from "../utils/functions"

http.setJwt(getJwt())

export async function login(username, password, errors) {
  try {
    const { data } = await http.post(process.env.AUTH_URL + "oauth/token", {
      username,
      password,
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      grant_type: process.env.GRANT_TYPE,
      scope: process.env.SCOPE,
    })

    if (data.access_token) {
      window.localStorage.setItem("token", data.access_token)
    }

    return true
  } catch (ex) {
    console.log("ex", ex.response)
    window.localStorage.removeItem("token")
    manageErrors(ex, errors)
    return { ...anonymousUser }
  }
}

export function loginWithFacebook(response) {
  window.localStorage.removeItem("token")
  window.localStorage.setItem("facebookToken", JSON.stringify(response))
}

export function loginWithJwt(jwt) {
  window.localStorage.setItem("token", jwt)
}

export function logout() {
  window.localStorage.removeItem("token")
  window.localStorage.removeItem("facebookToken")
  window.localStorage.removeItem("visioncare_user")
  initLocalCart()
}

export async function getCurrentUser() {
  try {
    const token =
      isBrowser() && window.localStorage.getItem("token")
        ? window.localStorage.getItem("token")
        : null

    let user = window.localStorage.getItem("visioncare_user")
    if (user) {
      user = JSON.parse(user)
    } else {
      user = { ...anonymousUser }
      if (token) {
        try {
          const { data } = await http.get(process.env.API_URL + "user/me", {
            headers: { Authorization: "Bearer " + token },
          })
          user = data.data
          window.localStorage.setItem("visioncare_user", JSON.stringify(user))
        } catch (ex) {
          window.localStorage.removeItem("token")
          let errors = {}
          if (!manageErrors(ex, errors)) {
            window.location = "/login"
          }
        }
      }
    }

    return user
  } catch (ex) {
    return null
  }
}

export async function register(data, errors) {
  try {
    const userEndpoint = process.env.API_URL + "clients/register"
    await http.post(userEndpoint, data)
    return true
  } catch (ex) {
    manageErrors(ex, ex.response)
    return false
  }
}

export async function changePassword(email) {
  try {
    await http.post(process.env.AUTH_URL + "password/email", { email })
    return true
  } catch (error) {
    return false
  }
}

export async function resendEmail(email) {
  await http.post(process.env.AUTH_URL + "email/resend", {
    email,
  })
}

export async function resetPassword(data) {
  const { email, token, password, password_confirmation } = data
  try {
    await http.post(process.env.AUTH_URL + "password/reset", {
      email,
      token,
      password,
      password_confirmation,
    })
    return true
  } catch (error) {
    return false
  }
}

export function getJwt() {
  return isBrowser() && window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : null
}

export default {
  login,
  loginWithFacebook,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  register,
  changePassword,
}
