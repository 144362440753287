import { toast } from "react-toastify"
import Moment from "moment"
import "moment/locale/it"

Moment.locale("it")

export const isBrowser = () => typeof window !== "undefined"

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes === 0) return "0 Byte"
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
}

export function getProgressCompleted(loaded, total) {
  return Math.round((loaded / total) * 100)
}

export function dateTimeToDate(originalDate) {
  let date = new Date(originalDate)

  date = Moment(date).format("DD MMM Y")
  if (date === "Invalid date") {
    date = ""
  }
  return date
}

export function dateTimeToHour(originalDate) {
  let date = new Date(originalDate)
  date = Moment(date).format("hh:mm")
  if (date === "Invalid date") {
    date = ""
  }
  return date
}

export function httpHeaders() {
  let token =
    isBrowser() && window.localStorage.getItem("token")
      ? window.localStorage.getItem("token")
      : {}

  return {
    headers: { Authorization: "Bearer " + token },
  }
}

export function formatCurrency(price) {
  return parseFloat(Math.round(price * 100) / 100).toFixed(2) + " €"
}

export function transformDataReactToLaravel(data) {
  let dataLaravel = {}
  dataLaravel.id = data.id
  dataLaravel.locale = data.locale

  // Attributes
  dataLaravel.attributes = { ...data }

  // Relationships
  dataLaravel.relationships = {}

  // Meta
  dataLaravel.relationships.meta = {}
  dataLaravel.relationships.meta.data = []

  /*
      Esempio meta data
      dataToSend.relationships.meta.data.push({
      attributes: { key: "lucky_number", value: data.lucky_number }
      });
      */

  return dataLaravel
}

export function initLocalCart() {
  let cart = {}
  cart.store = { id: 0 }
  cart.products = []
  cart.dateUpdate = new Date()
  setLocalCart(cart)
}

export function getLocalCart() {
  // Se il Cart non esiste lo inizializzo
  let cart =
    isBrowser() && window.localStorage.getItem("visioncare_cart")
      ? JSON.parse(window.localStorage.getItem("visioncare_cart"))
      : null

  if (cart === undefined || cart === null || cart === "") {
    initLocalCart()
  }

  // Controllo che il carrello non abbia superato il suo tempo di vita nella localstorage altrimenti lo uccido
  // Il tempo di vita è memorizzato dentro config.json
  cart =
    isBrowser() && window.localStorage.getItem("visioncare_cart")
      ? JSON.parse(window.localStorage.getItem("visioncare_cart"))
      : null
  const now = new Date()
  let dateIn = Moment(cart.dateUpdate)
  let dateOut = Moment(now)
  let dateDiff = dateOut.diff(dateIn, "minutes")
  if (dateDiff > process.env.CART_LIFE_TIME_IN_MINUTES) {
    initLocalCart()
  }

  // Ora son sicuro che esiste quindi lo leggo e lo restituisco
  cart =
    isBrowser() && window.localStorage.getItem("visioncare_cart")
      ? JSON.parse(window.localStorage.getItem("visioncare_cart"))
      : null

  return cart
}

export function setLocalCart(cart) {
  cart.dateUpdate = new Date()
  window.localStorage.setItem("visioncare_cart", JSON.stringify(cart))
}

export function getMetaValue(data, key) {
  if (!data.relationships.meta.data) {
    return ""
  }
  for (let i = 0; i < data.relationships.meta.data.length; i++) {
    if (data.relationships.meta.data[i].attributes.key === key) {
      return data.relationships.meta.data[i].attributes.value
    }
  }
  return ""
}

export function CL(a, b, c, d, e, f) {
  console.log(a, b, c, d, e, f)
}

/*export function toastErrors(errors) {
  toast.error(errors.message)
  Object.keys(errors.validation.attributes).forEach(function(key) {
    toast.error(key + ": " + errors.validation.attributes[key][0])
  })
}*/

export function myToast(message) {
  toast.error(message)
}

export function manageErrors(ex, errors) {
  if (
    !ex.hasOwnProperty("response.data.errors.validation.attributes") ||
    !ex.hasOwnProperty("response.data.errors.message")
  ) {
    return false
  }
  toast.error(ex.response.data.errors.message)

  Object.keys(ex.response.data.errors.validation.attributes).forEach(function(
    key
  ) {
    errors[key] = ex.response.data.errors.validation.attributes[key][0]
  })

  return true
}

export function transformApiProductsInGroupedOptions(products) {
  let groupedOptions = []
  for (let i = 0; i < products.data.length; i++) {
    let item = {}
    item.label = products.data[i].attributes.label
    item.value = products.data[i].attributes.label
    item.id = products.data[i].id
    let options = []
    const data = products.data[i].relationships.product_models.data
    for (let j = 0; j < data.length; j++) {
      let model = {}
      model.id = data[j].id
      model.code = data[j].attributes.code
      model.label = data[j].attributes.label
      model.value = data[j].attributes.label
      model.is_toric = data[j].meta.is_toric
      model.is_free_sample = data[j].meta.is_free_sample
      options.push(model)
    }
    item.options = [...options]
    groupedOptions.push({ ...item })
  }
  return groupedOptions
}

export function transformArrayToOptions(myArray, extraFieldType) {
  let options = []
  for (let i = 0; i < myArray.length; i++) {
    let option = {}
    option.label = myArray[i]
    option.value = myArray[i]
    option.type = extraFieldType
    options.push(option)
  }
  return [...options]
}

export function apiPayments2SelectPayments(myArray) {
  let options = []
  for (let i = 0; i < myArray.length; i++) {
    let option = {}
    option.id = myArray[i].id
    option.label = myArray[i].attributes.label
    option.value = myArray[i].attributes.code
    options.push(option)
  }
  return [...options]
}

export function apiStore2SelectStores(apiStores) {
  const stores = []
  for (let i = 0; i < apiStores.length; i++) {
    let store = {}
    store.id = apiStores[i].id
    store.primary = apiStores[i].attributes.primary
    store.value = apiStores[i].relationships.address.data.attributes.full_name
    store.obj = apiStores[i]
    store.fullName =
      apiStores[i].relationships.address.data.attributes.full_name
    store.fullAddress =
      apiStores[i].relationships.address.data.attributes.address_line +
      " " +
      apiStores[i].relationships.address.data.attributes.city
    store.label =
      apiStores[i].relationships.address.data.attributes.full_name +
      " - " +
      store.fullAddress
    stores.push(store)
  }
  return stores
}

export function apiStore2SelectStore(apiStore) {
  let store = {}
  store.id = apiStore.id
  store.primary = false

  console.log("apiStore", apiStore)
  store.primary = apiStore.obj.attributes.primary
  store.value = apiStore.obj.relationships.address.data.attributes.full_name
  store.obj = apiStore
  store.fullName = apiStore.obj.relationships.address.data.attributes.full_name
  store.fullAddress =
    apiStore.obj.relationships.address.data.attributes.address_line +
    " " +
    apiStore.obj.relationships.address.data.attributes.city
  store.label =
    apiStore.obj.relationships.address.data.attributes.full_name +
    " - " +
    store.fullAddress
  return store
}

export function getStoreLabelExtended(store) {
  if (!store) {
    return ""
  }
  let s = ""
  try {
    s = store.relationships.address.data.attributes.full_name
  } catch (error) {}
  return s
}

export function getLastUrlPath(path) {
  let v = path.split("/")
  let lastPath = v[v.length - 1]
  if (lastPath === "") {
    lastPath = v[v.length - 2]
  }
  return lastPath
}

export function sIsNumber(s) {
  return !(s !== s) && s !== ""
}
